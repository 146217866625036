import { useTranslation } from '@pancakeswap/localization'
import { Button, Flex } from '@pancakeswap/uikit'
import Container from 'components/Layout/Container'
import PageFullWidth from 'components/Layout/PageFullWidth'
import Link from 'next/link'
import styled from 'styled-components'

const NotFound = () => {
    const { t } = useTranslation()

    return (
        <PageFullWidth>
            <CsContainer>
                <Flex width="100%" height="100%" justifyContent="space-between" flexWrap="wrap">
                    <ColLeft>
                        <img src="/images/giftcards/404.png" alt="not-found"/>
                    </ColLeft>
                    <ColRight>
                        <Flex width="100%" height={["200px",,"250px"]} justifyContent="space-between" flexDirection='column'>
                            <Flex width="100%" flexDirection="column" alignItems={["center",,"flex-start"]} justifyContent={["center",,"flex-start"]}>
                                <Flex width="100%" justifyContent={["center",,"flex-start"]}>
                                    <CsHeading isSmall={false}>Oops!</CsHeading>
                                </Flex>
                                <Flex width="100%" mt="2rem" justifyContent={["center",,"flex-start"]}>
                                    <CsHeading isSmall={!false}>Không tìm thấy trang</CsHeading>
                                </Flex>
                            </Flex>
                            <Flex width="100%" justifyContent={["center",,"flex-start"]}>
                                <Link href="/">
                                    <Button style={{borderRadius:"40px"}}>Quay về trang chủ </Button>
                                </Link>
                            </Flex>
                        </Flex>
                    </ColRight>
                </Flex>
            </CsContainer>
        </PageFullWidth>
    )
}

export default NotFound

const CsContainer = styled(Container)`
    width: 100%;
    max-width: 1380px;
    height: 90vh;
    @media screen and (max-width: 768px) and (min-width: 601px) {
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 60px;
    }
    @media screen and (max-width: 600px) {
        padding-left: 15px;
        padding-right: 15px;
        height: auto;
        padding-bottom: 3rem;
    }
`
const CsHeading = styled.h1<{isSmall:boolean}>`
    font-weight: ${({ isSmall }) => isSmall ? "400" : "700"};
    font-size: ${({ isSmall }) => isSmall ? "40px" : "70px"};
    @media screen and (max-width:1000px) and (min-width:601px) {
        font-size: ${({ isSmall }) => isSmall ? "35px" : "55px"};
    }
    @media screen and (max-width:600px){
        font-size: ${({ isSmall }) => isSmall ? "35px" : "45px"};
    }
`
const ColRight = styled(Flex)`
    width: 50%;
    height: 100%;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width:600px) {
        width: 100%;
    }
`
const ColLeft = styled(Flex)`
    width: 50%;
    height: 100%;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    > img {
        width: 450px;
        height: auto;
    }
    @media screen and (max-width:600px) {
        width: 100%;
        > img {
            width: 200px;
            height: auto;
        }
    }
`